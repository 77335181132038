<template>
  <div>
    <!--loading-->
    <vs-progress color="primary" :height="1" v-if="isLoadingInitData"/>

    <div class="vx-row mb-3">
      <div class="vx-col lg:w-9/12 w-full">
        <vx-card>
          <vs-tabs alignment="fixed" v-model="activeTab">
            <vs-tab label="Detail Cash Advance" icon-pack="feather">
              <TabDetail :isActive="activeTab === 0" :initData="initData"/>
            </vs-tab>
          </vs-tabs>
        </vx-card>
      </div>

      <div class="vx-col lg:w-3/12 w-full mt-3 lg:mt-0">
        <!--approval progress-->
        <vx-card>
          <div class="flex items-center mb-base">
            <feather-icon icon="LayersIcon" svgClasses="h-5 w-5" class="mr-2"/>
            <h5 class="font-medium">Approval Progress</h5>
          </div>
          <ApprovalProgress
            ref="approvalProgress"
            type="CASH-ADVANCE"
            :id-ref="$route.params.idCashAdvance"
            :isShowSigningAction="true"
            @signingClicked="onClickSigningAction"/>
        </vx-card>
      </div>
    </div>

    <!--modals-->
    <CashAdvanceApprovalSigning :isActive.sync="modalSigning.active"
                             :idCashAdvance="modalSigning.idCashAdvance"
                             :status="modalSigning.status"
                             @success="onSigned"/>
  </div>
</template>

<script>
import { mapState } from 'vuex'
import moduleCashAdvanceApproval from '@/store/modules/approvals/cash-advance-approval/cash-advance-approval.store'
import CashAdvanceRepository from '@/repositories/procurement/cash-advance-repository'

export default {
  name: 'CashAdvanceApprovalShow',
  components: {
    TabDetail: () => import('@/views/pages/approvals/cash-advance-approval/parts/TabDetail'),
    CashAdvanceApprovalSigning: () => import('@/views/pages/approvals/cash-advance-approval/CashAdvanceApprovalSigning'),
    ApprovalProgress: () => import('@/views/components/approval-progress/ApprovalProgress')
  },
  mounted () {
    this.getInitData()
  },
  data () {
    return {
      isLoadingInitData: false,
      initData: {
        cashAdvance: {},
        cashAdvanceD: []
      },
      modalSigning: {
        active: false,
        idCashAdvance: this.$route.params.idCashAdvance,
        status: null
      }
    }
  },
  computed: {
    activeTab: {
      get () {
        return this.$store.state.approvals.cashAdvanceApproval.activeTab
      },
      set (value) {
        this.$store.commit('approvals/cashAdvanceApproval/SET_ACTIVE_TAB', value)
      }
    },
    ...mapState({
      dataTabDetail: state => state.approvals.cashAdvanceApproval.tabDetail
    })
  },
  methods: {
    onSigned () {
      this.getInitData()
      this.$refs.approvalProgress.refresh()
    },

    getInitData () {
      this.isLoadingInitData = true

      const idCashAdvance = this.$route.params.idCashAdvance
      CashAdvanceRepository.show(idCashAdvance)
        .then(response => {
          this.initData.cashAdvance = response.data.data
          this.initData.cashAdvanceD = this.initData.cashAdvance.details
        })
        .catch(error => {
          if (error.response.status === 404) {
            this.$router.push({ name: '404' })
          } else {
            console.log(error)
            this.notifyError('Terjadi kesalahan.')
          }
        })
        .finally(() => {
          this.isLoadingInitData = false
        })
    },

    onClickSigningAction (status) {
      this.modalSigning.status = status
      this.modalSigning.active = true
    }
  },
  beforeCreate () {
    this.$store.registerModule(['approvals', 'cashAdvanceApproval'], moduleCashAdvanceApproval)
  },
  beforeDestroy () {
    this.$store.commit('approvals/cashAdvanceApproval/RESET_STATE')
    this.$store.unregisterModule(['approvals', 'cashAdvanceApproval'])
  }
}
</script>
